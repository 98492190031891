/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 14:31
 *
 * Tohle je potřeba dokud bude někde v kodu rel="tooltip"
 */


window.webnode2015_handleTooltip = function ($)
{
	/*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas, David Knight. Dual MIT/BSD license */
	window.matchMedia || (window.matchMedia = function() {
		"use strict";

		// For browsers that support matchMedium api such as IE 9 and webkit
		var styleMedia = (window.styleMedia || window.media);

		// For those that don't support matchMedium
		if (!styleMedia) {
			var style       = document.createElement('style'),
				script      = document.getElementsByTagName('script')[0],
				info        = null;

			style.setAttribute('type', 'text/css');
			style.setAttribute('id', 'matchmediajs-test');

			script.parentNode.insertBefore(style, script);

			// 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
			info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.getAttribute('currentStyle');

			styleMedia = {
				matchMedium: function(media) {
					var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

					// 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
					if (
						style.hasAttribute('styleSheet') &&
						style.getAttribute('styleSheet')
					)
					{
						style.setAttribute(
							'styleSheet',
							Object.assign(
								{},
								style.getAttribute('stylesheet'),
								{cssText:text}
								)
						);
					} else {
						style.setAttribute('textContent', text);
					}

					// Test if media query is true or false
					return info.width === '1px';
				}
			};
		}

		return function(media) {
			return {
				matches: styleMedia.matchMedium(media || 'all'),
				media: media || 'all'
			};
		};
	}());

	//ošetření touched-prevent-default např. v labelu
	$('body').on('click','.touched-prevent-default', function(e) {
		if ($('body').hasClass('touched')) {
			e.preventDefault();
		}
	});

	//obsluha tooltipu
	$( function()
	{
		var tooltip_trigger = 'span[rel~=tooltip], a[rel~=tooltip]';
		var tooltip_container = $('<div id="tooltip" class="w-tooltip"></div>');
		var tooltip_arrow = $('<div id="tooltip-arrow" class="w-tooltip-arrow"></div>');
		var pricelist_section = $('main > div.pricelist');

        var tooltip_timer_on, tooltip_timer_off;

        // zpoždění pro objevení
        var tooltip_delay_on = 300;

        // zpoždění pro shození
        var tooltip_delay_off = 500;

        // zpoždění pro shození
        var tooltip_behavior = 'normal';

        // délka animací
        var tooltip_animation_speed = 150;

		var tooltip_mq_3cols = window.matchMedia( "(max-width: 959px)" );
		var tooltip_mq_4cols = window.matchMedia( "(max-width: 1199px)" );

        // ošetření situace kdy je tooltip na mobilu a umístěn např. v labelu
        $('.touched').on('click', tooltip_trigger, function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
        });

        // na dotykových zařízeních bez zpoždění
        if (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            tooltip_delay_on = 0;
            tooltip_delay_off = 0;
        }

		tooltip_container.appendTo('body');
		tooltip_arrow.appendTo('body');

		//event delegace pro fungování tooltipů vytvořených později
		$('body').on('mouseenter mouseleave click',tooltip_trigger, tooltip);


		function tooltip(e){

            var getDataAttrValue = function (attribute) {
                var target = $(e.currentTarget);
                var value = target.attr(attribute);
                if (value === undefined) {
                    return '';
                } else {
                    return value;
                }
            };

            var remove_tooltip = function () {
                clearTimeout(tooltip_timer_on);
                // dokončit případnou rozjetou animaci do finální hodnoty
                tooltip_arrow.finish();
                tooltip_container.finish();
                clearTimeout(tooltip_timer_on);
                tooltip_container.removeClass('interactive top');
                tooltip_arrow.removeClass('top');
				tooltip_container.html('').css({top: 0, left: 0, opacity: 0});
				tooltip_arrow.css({top: 0, left: 0, opacity: 0});
			};

            var handle_bubble_events = function (e) {

                if (tooltip_behavior == 'interactive') {
                    clearTimeout(tooltip_timer_off);
                }

                if (e.type === 'mouseleave') {
                    clearTimeout(tooltip_timer_off);
                    remove_tooltip();
                }
                if (e.type === 'click') {
                    e.stopPropagation();
                    // klikání na odkazy nezavře bublinu
                    if (e.target.tagName.toLowerCase() != 'a') {
                        remove_tooltip();
                    }
                }
            };

            // vlastní event pro shození tooltipu např. v případě že "pod rukama" systému zrušíme v DOM trigger prvek
			$( document ).on('hidewebnodetooltip', remove_tooltip);

			if( e.type === 'mouseenter'  ||  e.type === 'click') {

				if ((e.type === 'click') && $('body').hasClass('desktop')) return;

                clearTimeout(tooltip_timer_off);

				tooltip_timer_on = setTimeout(function(){

					var init_tooltip = function()
					{

						var target = $(e.currentTarget);

                        // nezobrazovat tooltip pro prvky které jsou disabled (pomocí třídy)
						if (target.parent().hasClass('disabled')) return;

                        // obsah bubliny tooltipu
                        var tipHtmlContent = '';

                        if (getDataAttrValue('data-source') == '') {
                            //zdroj dat kompatibilní s původní verzí, kde existoval jen data-title a směřoval na # selektor
						var tipSource = target.attr('data-title');
                            tipHtmlContent = $('#' + tipSource).html();
                        } else {
                            //nový způsob odkud brát obsah tooltipu, buď z data-title atributu, nebo z nějakého elementu
                            if (getDataAttrValue('data-source') == 'data-title') {
                                tipHtmlContent = getDataAttrValue('data-title');
                            } else {
                                if (getDataAttrValue('data-source') != '' && $(getDataAttrValue('data-source').length)) {
                                    //pokud z elementu, počítá se že součástí dat bude i . nebo # s názvem selektoru
                                    tipHtmlContent = $(getDataAttrValue('data-source')).html();
                                } else {
                                    //je problém se zdrojem dat, nezobrazíme tooltip vůbec
                                    return;
                                }

                            }
                        }


                        // specialita pro tooltipy v ceníku
						if ( pricelist_section.length ) {
							if (!pricelist_section.hasClass('two-cols')) {

								if ((pricelist_section.hasClass('three-cols') && tooltip_mq_3cols.matches) || (!pricelist_section.hasClass('three-cols') && tooltip_mq_4cols.matches)){

									var currentDt = target.closest('dt');
									var paidDd = currentDt.next();
									var pairDdValue = '';

									if (currentDt.hasClass('pricelist-feature-show-value-in-tooltip')) {
										pairDdValue =  ': ' + paidDd.text().trim();
									}

									var tipHtmlTitle = target.text().trim() + pairDdValue;
									if (tipHtmlTitle != '') {
										tipHtmlContent =  '<h3>' + tipHtmlTitle + '</h3><p>' + tipHtmlContent + '</p>';
									}

								}
							}
						}

                        tooltip_arrow.finish();
                        tooltip_container.finish();
                        tooltip_arrow.css({opacity: 0});
						tooltip_container.html(tipHtmlContent).css({opacity: 0});

                        // třída pro vytvoření safe oblasti pro přesun do bubliny. jen pokud je v bublině odkaz
                        if (tipHtmlContent && tipHtmlContent.indexOf('<a') !== -1) {
                            tooltip_container.addClass('interactive');
                            tooltip_behavior = 'interactive';
                        } else {
                            tooltip_container.removeClass('interactive');
                            tooltip_behavior = 'normal';
                        }

						if( $(window).width() < tooltip_container.outerWidth() * 1.5) {
							tooltip_container.css('max-width', $(window).width() / 2 + 75);
                        } else {
                            tooltip_container.css('max-width', '');
						}

						//default pozicování nahoru nad target prvek
						var pos_left = target.offset().left + (target.outerWidth() / 2) - (tooltip_container.outerWidth() / 2);
						var pos_top  = target.offset().top - tooltip_container.outerHeight() - 10;
						var pos_arrow_left = target.offset().left + (target.outerWidth() / 2) - 10;
						var pos_arrow_top = target.offset().top - 10;
                        var special_case_positioning = false;

						//záporný left (může nastat kvůli odečtu poloviny šířky tooltipu při targetu který je hodně vlevo)
						if( pos_left < 0 ) {
							pos_left = target.offset().left + target.outerWidth() / 2 - 20;
                            special_case_positioning = true;
						}

						//když by se pravá hrana tooltipu nevešla do okna prohlížeče
						if( pos_left + tooltip_container.outerWidth() > $(window).width() ) {
							pos_left = target.offset().left - tooltip_container.outerWidth() + target.outerWidth() / 2 + 20;
							if( pos_left < 0 ) {
                                pos_left = 0;
                                special_case_positioning = true;
							}
						}

						var scrollTop = $('html,body').scrollTop();
                        var scrollBottom = $(window).height() + scrollTop;

						//když by okno vyšlo nad nascrolovanou oblast dokumentu v okně prohlížeče, umístí se tooltip dolů
						if( pos_top < scrollTop ) {
							pos_top  = target.offset().top + target.outerHeight() + 10;
							pos_arrow_top = pos_top - 10;
							tooltip_arrow.addClass('top');
							tooltip_container.addClass('top');
						}
						else {
							tooltip_arrow.removeClass('top');
                            tooltip_container.removeClass('top');
                        }


                        //do hry vstupuje ještě snaha zůstat uvnitř zvoleného kontejneru, pokud je to možné

                        if (getDataAttrValue('data-viewport') != '' && $(getDataAttrValue('data-viewport').length)) {

                            var viewPortElement = $(getDataAttrValue('data-viewport'));

                            var vpe_pos_top = viewPortElement.offset().top;
                            var vpe_pos_bottom = viewPortElement.offset().top + viewPortElement.outerHeight();
                            var vpe_pos_left = viewPortElement.offset().left;
                            var vpe_pos_right = viewPortElement.offset().left + viewPortElement.outerWidth();

                            //console.log('pos-top: ' + pos_top + ', pos-left:' + pos_left + ', vpe-top:' + vpe_pos_top + ', vpe-btm:' + vpe_pos_bottom + ', vpe-left:' + vpe_pos_left + ', vpe-right:' + vpe_pos_right);

                            if (!tooltip_arrow.hasClass('top') && (vpe_pos_top > pos_top)) {
                                var pos_top_candidate = target.offset().top + target.outerHeight();
                                //console.log(pos_top_candidate + tooltip_container.outerHeight(), scrollBottom);
                                if (pos_top_candidate + tooltip_container.outerHeight() < scrollBottom) {
                                    pos_top = pos_top_candidate;
                                    pos_arrow_top = pos_top - 10;
                                    tooltip_arrow.addClass('top');
                                    tooltip_container.addClass('top');
                                }
                            }

                            if (special_case_positioning === false && (vpe_pos_right < pos_left + tooltip_container.outerWidth())) {
                                pos_left = target.offset().left - tooltip_container.outerWidth() + target.outerWidth() / 2 + 20;
                                special_case_positioning = true;
						}

                            if (special_case_positioning === false && (vpe_pos_left > pos_left)) {
                                //pos_left = target.offset().left + target.outerWidth() / 2 - 20;
                                //special_case_positioning = true;
                            }

                        }

                        //zobrazení tooltipu, defaultně s animací
                        if (getDataAttrValue('data-effect') != 'static') {
                            if (tooltip_arrow.hasClass('top')) {
                                tooltip_arrow.css({left: pos_arrow_left, top: pos_arrow_top + 20}).animate({
                                    top: '-=10',
                                    opacity: 1
                                }, tooltip_animation_speed);
                                tooltip_container.css({left: pos_left, top: pos_top + 20}).animate({
                                    top: '-=10',
                                    opacity: 1
                                }, tooltip_animation_speed);
                            } else {
                                tooltip_arrow.css({left: pos_arrow_left, top: pos_arrow_top - 10}).animate({
                                    top: '+=10',
                                    opacity: 1
                                }, tooltip_animation_speed);
                                tooltip_container.css({left: pos_left, top: pos_top - 10}).animate({
                                    top: '+=10',
                                    opacity: 1
                                }, tooltip_animation_speed);
                            }

                        }
                        else {
						tooltip_arrow.css({left: pos_arrow_left, top: pos_arrow_top, opacity: 1});
						tooltip_container.css({left: pos_left, top: pos_top, opacity: 1});
                        }

					};

					var resize_tooltip = function()
					{
						if (tooltip_container.css('opacity') == '0') return false;
						init_tooltip();
					};

					init_tooltip();

					$(window).on('resize orientationchange scroll mousewheel', function(e) {

						if ($('body').hasClass('touched')) {
							remove_tooltip();
						} else {
							resize_tooltip;
						}

					});

                    if (getDataAttrValue('data-viewport') != '' && $(getDataAttrValue('data-viewport').length)) {

                        var viewPortElement = $(getDataAttrValue('data-viewport'));
                        viewPortElement.on('resize scroll mousewheel', function (e) {

                            if ($('body').hasClass('touched')) {
                                remove_tooltip();
                            } else {
                                resize_tooltip;
                            }

                        });

                    }

					$('.touched .table-data-modal.open .table-data-modal-content').on('scroll mousewheel', function(e) {
						remove_tooltip();
					});

                    tooltip_container.on('mousenter mouseover mouseleave click', handle_bubble_events);

                }, tooltip_delay_on);
			}
			if( e.type === 'mouseleave') {

                if (tooltip_behavior == 'normal') {
                    tooltip_delay_off = 0;
                }
                tooltip_timer_off = setTimeout(function () {
                    if (getDataAttrValue('data-effect') != 'static') {
                        tooltip_container.animate({top: '-=10', opacity: 0}, tooltip_animation_speed, function () {
				remove_tooltip();
                        });
                        tooltip_arrow.animate({top: '-=10', opacity: 0}, tooltip_animation_speed, function () {
                            remove_tooltip();
                        });
                    } else {
                        remove_tooltip();
                    }
                }, tooltip_delay_off);

			}
		}

	});
}