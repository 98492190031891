/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 13:35
 *
 * Funkce které se volají jen po document ready, nebo resize a nemají závislosti
 */

function webnode2015_inicializers($) {

	/**
	 * Přiznak vykonávání JS a detekce typu zařízení
	 * View fix pro IE10 na Windows 8 a Windows Phone 8
	 */
	function deviceDetection() {
		// Příznak vykonávání JS
		$('html').removeClass('no-js').addClass('js');

		// Detekce typu zařízení
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			$('body').addClass('touched');
		}
		else {
			$('html').addClass('pc');
			$('body').addClass('desktop');
		}

		// IE 10 in Windows 8 and Windows phone 8 view fix
		// http://getbootstrap.com/getting-started/#support-ie10-width
		if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
			var msViewportStyle = document.createElement('style');
			msViewportStyle.appendChild(
				document.createTextNode(
					'@-ms-viewport{width:auto!important}'
				)
			);
			document.querySelector('head').appendChild(msViewportStyle);
		}

		// JMA: zatím tu třídu používám jen pro tiskové styly ve firefoxu pro order detail,
		// koncepčně to ale chci mít obecně pro portál jako takový, tj. ne jen pro orders.
		if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
		{
			document.getElementsByTagName("html")[0].classList.add('firefox');
		}
	}


	/**
	 * Nastavení tříd poměru stran headeru + JS fallback pro fullscreen hlavičku
	 */
	function portalHeader() {
		//korekce výšky hlavičky
		if ($('.fullscreen')[0]) {

			var minimumFullscreenHeaderHeight = 400;
			var setFullscreenHeaderHeight  = $(window).height();

			if (setFullscreenHeaderHeight < minimumFullscreenHeaderHeight) {
				setFullscreenHeaderHeight = minimumFullscreenHeaderHeight;
			}

			$('.fullscreen').css('height', setFullscreenHeaderHeight);
		}
		//manipulace s pozadím hlavičky
		if ($('.header').hasClass('hp-header')) {

			var headerAspectRatio =  ($(this).width()/$(this).height()).toFixed(2);


			if (headerAspectRatio > 2.45) {
				addClassHeaderAspectRatio('extreme-landscape');
			} else if (headerAspectRatio > 1.35) {
				addClassHeaderAspectRatio('landscape');
			} else if (headerAspectRatio < 0.5) {
				addClassHeaderAspectRatio('extreme-portrait');
			} else if (headerAspectRatio < 0.85) {
				addClassHeaderAspectRatio('portrait');
			}
			else {
				addClassHeaderAspectRatio('square');
			}

			function addClassHeaderAspectRatio(headerClass) {
				var classesForHeaderAspectRatio = ['landscape', 'extreme-landscape','square','portrait','extreme-portrait'];
				var index;
				for (index = 0; index < classesForHeaderAspectRatio.length; ++index) {

					if (classesForHeaderAspectRatio[index] == headerClass) {
						$('.hp-header').addClass(classesForHeaderAspectRatio[index]);
					} else {
						$('.hp-header').removeClass(classesForHeaderAspectRatio[index]);
					}
				}
			}
		}
	}


	return {
		deviceDetection: deviceDetection,
		portalHeader: portalHeader
	};
}

window.webnode2015_inicializers = webnode2015_inicializers;
