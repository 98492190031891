(function ($) {
	window.matchMedia || (window.matchMedia = function() {
		"use strict";

		// For browsers that support matchMedium api such as IE 9 and webkit
		var styleMedia = (window.styleMedia || window.media);

		// For those that don't support matchMedium
		if (!styleMedia) {
			var style       = document.createElement('style'),
				script      = document.getElementsByTagName('script')[0],
				info        = null;

			style.setAttribute('type', 'text/css');
			style.setAttribute('id', 'matchmediajs-test');

			script.parentNode.insertBefore(style, script);

			// 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
			info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.getAttribute('currentStyle');

			styleMedia = {
				matchMedium: function(media) {
					var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

					// 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
					if (
						style.hasAttribute('styleSheet') &&
						style.getAttribute('styleSheet')
					)
					{
						style.setAttribute(
							'styleSheet',
							Object.assign(
								{},
								style.getAttribute('stylesheet'),
								{cssText:text}
							)
						);
					} else {
						style.setAttribute('textContent', text);
					}

					// Test if media query is true or false
					return info.width === '1px';
				}
			};
		}

		return function(media) {
			return {
				matches: styleMedia.matchMedium(media || 'all'),
				media: media || 'all'
			};
		};
	}());

    //detekce ios a jeho verze
    function iOSversion() {
        var d, v;
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            d = {
                status: true,
                version: parseInt(v[1], 10) ,
                info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
            };
        }else{
            d = {status:false, version: false, info:''}
        }
        return d;
    }

    var iosVer = iOSversion();

    //******************************************
	//obsluha sticky menu
    //******************************************

    var currentRAf;
    var navlineElement = $('.nav-line-cbt-outer');
	if (!navlineElement.length) {navlineElement = $('.w-navline');}

	var tabsParentElement = $('.nav-line-cbt-tabs');
    if (!tabsParentElement.length) {tabsParentElement = $('.w-navline-tabs');}

    var lastWindowScrollTop = 0;
    var lastWindowHeight = 0;
	// pro blokování změn pro navline při otevření uživatelského menu na ios (měněno z fce pro obsluhu uživatelského menu)
	var controlNavline = 'released';

	$(window).on('scroll wheel resize orientationchange touchmove', function () {
        cancelAnimationFrame(currentRAf);
        currentRAf = requestAnimationFrame(updateNavlineState);
    });

    var updateNavlineState = function() {

        if (controlNavline == 'blocked') return;

        var windowHeight = window.innerHeight;
        var windowScrollTop = window.pageYOffset;

        if (windowScrollTop == lastWindowScrollTop && windowHeight == lastWindowHeight) return;

        var navlineHeight = navlineElement.outerHeight();
        var navlineOffset =  parseInt( navlineElement.css( 'padding-top' ) );


        $('.portal').toggleClass( 'nav-line-sticky', windowScrollTop > navlineHeight);

        if (windowScrollTop < navlineOffset) {
            navlinePosTop = -windowScrollTop;
        } else {
            navlinePosTop = -navlineOffset;
        }

        navlineElement.css( 'top', navlinePosTop );

        // tabsParentElement.find('ul li').each(function (index, element) {
		//
        //     var section = $(this).find('a').attr('href').replace('#','');
		//
        //     var offset = $('a[name="' + section + '"]').offset().top;
		//
		//
        //     if ($(this).next().length) {
        //         var nextSection = $(this).next().find('a').attr('href').replace('#','');
        //         var offsetNext = $('a[name="' + nextSection + '"]').offset().top;
        //     } else {
        //         var offsetNext = $('.lp-section-title').offset().top;
        //     }
		//
        //     var sectionHeight = offsetNext  - offset;
		//
        //     var myWindowScrollTop = windowScrollTop + navlineHeight - Math.abs(parseInt(navlineElement.css('top'))) + 200;
		//
		//
        //     if (offset <= myWindowScrollTop && offset + sectionHeight > myWindowScrollTop) {
        //         $(this).addClass('nav-line-cbt-tab-current');
        //     } else {
        //         $(this).removeClass('nav-line-cbt-tab-current');
		//
        //     }
		//
		//
		//
        // });

        lastWindowScrollTop = windowScrollTop;
        lastWindowHeight = windowHeight;

    };


    $(document).ready(function () {
		//spuštění při document.ready
        updateNavlineState();
    });

    //******************************************
    //obsluha videopřehrávače
    //******************************************

    $(document).ready(function ()
    {
        var videoElements = document.getElementsByTagName('video');
        if(videoElements.length === 0) return;

		var isMobileDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
		var intersectionObserverAvailable = ('IntersectionObserver' in window);


        function initVideoPlayer(videoElement)
        {
            if (!intersectionObserverAvailable) return;

			var hpVideoPlayerObserverOptions = {
                root: null,
                rootMargin: '0px',
                threshold: 0,
            };

			var hpVideoPlayerOnIntersection = function(entries)
            {
                if (entries[0].isIntersecting)
                {
                    videoElement.pause();
                    setTimeout(function()
                    {
                        videoElement.play();
                        document.title = '\u25b6 ' + document.title;
                    }, 50);
                }
                else
                {
                    videoElement.pause();
                    document.title = document.title.replace('\u25b6 ', '');
                }
            };

			var hpVideoPlayerObserver = new IntersectionObserver(hpVideoPlayerOnIntersection, hpVideoPlayerObserverOptions);
			var hpVideoPlayerWrapper = $(videoElement).closest('.lp-section-video')[0];
            hpVideoPlayerObserver.observe(hpVideoPlayerWrapper);
        }

        // Loop over all video elements
        for(var i = 0; i < videoElements.length; i++)
        {
            var videoElement = videoElements[i];

            if (isMobileDevice)
            {
                $(videoElement).siblings('.lp-section-video-button-play').removeClass('hide').addClass('open').on('click', function (e)
                {
                    $(e.currentTarget).removeClass('open');
                    if (intersectionObserverAvailable)
                    {
                        initVideoPlayer(videoElement);
                    }
                    else
                    {
                        setTimeout(function()
                        {
                            videoElement.play();
                        }, 50);
                    }
                });
            }
            else
            {
                initVideoPlayer(videoElement);
            }
        }
    });


	//obsluha uživatelského menu
	$('.user-menu-submit, .w-user-menu-submit, .w-notifications-menu-back').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        handleUserMenu('toggle');
    });

	//zavření mobilní verze uživatelského menu
	$('.user-menu-close, .w-user-menu-close').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        handleUserMenu('off');
    });

	// Ovladač submenu v user menu
	$('.user-menu-submenu-trigger, .w-user-menu-submenu-trigger').on('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();

        var submenuContentElementName = '.user-menu-submenu-content';
        if (e.target.className.indexOf('w-') !== -1) {
            submenuContentElementName = '.w-user-menu-submenu-content';
        }


        var userMenuItemParent = $(this).closest('li');
		var userMenuItemContent = userMenuItemParent.find(submenuContentElementName);


        if (!userMenuItemContent.is(':animated')) {

			//ostatní submenu
			$(submenuContentElementName).not(userMenuItemContent).animate({ height: 0, opacity: 0 }, 150, function() {
                $(this).closest('li').removeClass('open-content');
                $(this).css({'height': '','opacity': '','display': ''});
            }).closest('li').removeClass('open-trigger');

			//aktuální submenu
            userMenuItemParent.toggleClass('open-trigger');
            userMenuItemContent.animate({ height: 'toggle', opacity: 'toggle' }, 150, function() {
                $(this).css({'height': '','opacity': '','display': ''});
                userMenuItemParent.toggleClass('open-content');
            });

        }

    });

	// Zavření usermenu při kliku jinde než v usermenu
    $(document).on("click", (function(e) {
			var filter = $('.w-notifications-menu, .user-menu, .w-user-menu');
            if(!$(e.target).closest(filter).length && !$(e.target).is(filter)) {
                handleUserMenu('off');
            }
        })
    );

	// Zavření usermenu stisknutím klávesy "Esc"
    $(document).keyup(function (e) {
        if (e.keyCode == 27) {
            handleUserMenu('off');
        }
    });

	// Obsluha uživatelského menu


    var handleUserMenuTimerOn;
    var handleUserMenuTimerOff;

    function handleUserMenu(actionUserMenu) {
        var portalRoot = $('.portal');

        if (portalRoot.hasClass('active-user-menu') || actionUserMenu == 'off') {
            portalRoot.removeClass('active-user-menu');
			portalRoot.removeClass('active-notifications-menu');
            portalRoot.removeClass('active-ios-overlay');
            if (iosVer.status && portalRoot.attr('data-last-y-offset')) {
                portalRoot.css('top','');
                portalRoot.css('bottom','');
                clearTimeout(handleUserMenuTimerOn);
                handleUserMenuTimerOff = setTimeout(function() {
                    window.scrollTo(0,parseInt(portalRoot.attr('data-last-y-offset')));
                    portalRoot.removeAttr('data-last-y-offset');
                    controlNavline = 'released';
                }, 25);

            } else {
                portalRoot.removeClass('active-user-menu');
            }
        } else {
			portalRoot.removeClass('active-notifications-menu');
            if (iosVer.status) {
                portalRoot.attr('data-last-y-offset', window.pageYOffset);
                controlNavline = 'blocked';
                clearTimeout(handleUserMenuTimerOn);
                clearTimeout(handleUserMenuTimerOff);
                handleUserMenuTimerOn = setTimeout(function() {
                    portalRoot.addClass('active-user-menu');
                    portalRoot.addClass('active-ios-overlay');
                    portalRoot.css('top',-parseInt(portalRoot.attr('data-last-y-offset')));
                    portalRoot.css('bottom',0);
                    window.scrollTo(0, 0);
                }, 25);
                setTimeout(function() {
                    $('.user-menu, .w-user-menu').removeClass('reopen');
                }, 50);

            } else {
                portalRoot.addClass('active-user-menu');
                setTimeout(function() {
                    $('.user-menu, .w-user-menu').removeClass('reopen');
                }, 50);
            }

        }
    }



	$('.w-user-menu-badge').on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		$('.w-user-menu-level-2').removeClass('state-hidden');
		$('.w-user-menu-level-1').addClass('state-hidden');

	});

	$('.w-user-menu-back').on('click', function (e) {
		e.preventDefault();
		e.stopPropagation();

		$('.w-user-menu-level-1').removeClass('state-hidden');
		$('.w-user-menu-level-2').addClass('state-hidden');

	});

    var handleHashTopTimer;
    var handleHashToGoTimer;
    $('.w-user-menu-features-items li a, .user-menu-features-items li a').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        clearTimeout(handleHashTopTimer);
        clearTimeout(handleHashToGoTimer);
        handleUserMenu('off');

        var hashToGo =  $(this).attr('href');
        handleHashTopTimer = setTimeout(function() {

			//kvůli ios
            window.location.hash = 'top';

            handleHashToGoTimer = setTimeout(function() {
                window.location = hashToGo;
                HandleEffectWhenJumpingToAnchor();
            }, 2);

        }, 50);
    });

    // tabsParentElement.find('ul li a').on('click', function (e) {
	//
    //     HandleEffectWhenJumpingToAnchor();
	//
    // });

    var clickTabItemEffectEnable;
    var clickTabItemEffectShow;
    var firstRun = true;

    function HandleEffectWhenJumpingToAnchor() {
        if (firstRun) {
            $('.lp-section').addClass('lp-section-visible');
            firstRun = false;
        }

        $('.portal').addClass('enable-main-overlay show-main-overlay');

        clearTimeout(clickTabItemEffectShow);
        clearTimeout(clickTabItemEffectEnable);

        clickTabItemEffectShow = setTimeout(function() {
            $('.portal').removeClass('show-main-overlay');
        }, 50);


        clickTabItemEffectEnable = setTimeout(function() {
            $('.portal').removeClass('enable-main-overlay');
        }, 700);
    }


})(jQuery);
