/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 14:08
 *
 * Sada funkcí, které používá hafo věcí, ale samy nemají závislosti (leda mezi sebou)
 *
 * COKOLIV co se ti nelíbí PŘEPIŠ ... tohle je jen rychlé rozdělení
 */

function webnode2015_dependency_fns($) {


	/**
	 * Stará se o class na body, které určují jak se bude zobrazovat obsah modalniho okna
	 */
	function classControl(content) {
		var b = $('body');

		b.removeClass('active-user-menu');

		switch (content) {

			case "active-preview" :
				b.addClass('active-preview')
					.removeClass('active')
					.removeClass('active-menu')
					.removeClass('active-data')
					.removeClass('active-table-data');
				break;


			case "remove-active":
				b.removeClass('active');
				break;

			default:
				b.removeClass('active')
					.removeClass('active-menu')
					.removeClass('active-data')
					.removeClass('active-preview')
					.removeClass('active-table-data');
		}
	}

	return {
		classControl: classControl
	}
}

window.webnode2015_dependency_fns = webnode2015_dependency_fns;
