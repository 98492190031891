/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 14:21
 */

function webnode2015_clickEvents($, dependencies)
{
	/**
	 * Zpracování eventu pro modaly, boční menu, data filtr, ...
	 */
	function clickEvents()
	{
		// rozbalení a skrytí sekcí patičky v mobilním zobrazení - na HP/LP a textovych strankach bez react-u
		$('.footer-full .footer-columns .column').on('click', 'h3', function(e) {

			e.preventDefault();

			if ($(window).width() > 599)  { return;}

			var t = $(this).closest('.column');

			if (!t.is(':animated')) {

				if(t.hasClass('open')) {
					t.removeClass('open-switch');
					t.find('h3 + ul').slideUp(300, function() {
						t.removeClass('open').find('h3 + ul').css({'height': '','opacity': '','display': ''});
					});
				} else {
					t.addClass('open-switch');
					t.find('h3 + ul').slideDown(300, function() {
						t.addClass('open').find('h3 + ul').css({'height': '','opacity': '','display': ''});
					});
				}

			}

		});
	}

	return clickEvents;
};

window.webnode2015_clickEvents = webnode2015_clickEvents;
