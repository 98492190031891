(function ($) {

		//detekce ios a jeho verze
		function iOSversion() {
			var d, v;
			if (/iP(hone|od|ad)/.test(navigator.platform)) {
				v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				d = {
					status: true,
					version: parseInt(v[1], 10) ,
					info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
				};
			}else{
				d = {status:false, version: false, info:''}
			}
			return d;
		}

		var iosVer = iOSversion();

		var currentRAf;
		var lastScrollTop;
		var lastWindowWidth;
	    var lastWindowHeight;
		var countRedraws = 0;

		var navlineElement = $('.nav-line-outer');
		if (!navlineElement.length) {navlineElement = $('.w-navline');}


		$(window).on('load scroll wheel resize orientationchange touchmove', function () {
			cancelAnimationFrame(currentRAf);
			currentRAf = requestAnimationFrame(handleSecNavTabs);
		});

		$(document).ready(function () {
			handleSecNavTabs();
			var hint = $('.lp-kit-hp-sec-nav-select-hint').contents().get(0);
			var selectHintText = hint ? hint.nodeValue : '';
			if(iosVer.status) {
				//u iOS selectu nešlo skákat na první položku, pokud uživatel nezaroloval na jinou a nevrátil se zas na první
				//proto jen pro iOS přidáváme speciální první položku s hint textem (tím vyřešíme aby jazykový string nebyl v JS)
				$('.lp-kit-hp-sec-nav select').prepend(new Option(selectHintText, ''));
				$('.lp-kit-hp-sec-nav select option:first').attr('disabled', 'true');
			}
			if ($('.lp-kit-hp-sec-nav select')[0]) {
				$('.lp-kit-hp-sec-nav select')[0].selectedIndex = -1;
			}
		});

		var handleSecNavTabs = function() {

			var  windowScrollTop = window.pageYOffset;
			var w=window,
				d=document,
				e=d.documentElement,
				g=d.getElementsByTagName('body')[0],
				windowWidth=w.innerWidth||e.clientWidth||g.clientWidth,
				windowHeight=w.innerHeight||e.clientHeight||g.clientHeight;

			if (lastScrollTop == windowScrollTop && lastWindowWidth == windowWidth && lastWindowHeight == windowHeight) {
				countRedraws++;
				if (countRedraws > 2) return;
			} else {
				countRedraws = 0;
			}

			lastScrollTop =  windowScrollTop;
			lastWindowWidth = windowWidth;
			lastWindowHeight = windowHeight;

			var menuContainer = $('.lp-kit-hp-sec-nav');
			var menuContainerInner = $('.lp-kit-hp-sec-nav-inner');
			var menuContainerInnerHeight = parseInt(menuContainerInner.height());
			var navlineHeight = navlineElement.outerHeight() - Math.abs(parseInt(navlineElement[0].style.top));

			if (menuContainer.offset() && menuContainer.offset().top - menuContainerInnerHeight <  windowScrollTop + 1) {
				menuContainerInner.addClass('lp-kit-hp-sec-nav-sticky');
				menuContainerInner.css('top', navlineHeight - 1);
			} else {
				menuContainerInner.removeClass('lp-kit-hp-sec-nav-sticky');
				menuContainerInner.css('top', '');
			}

			var categoryMenuWidth = 0;

			//var defaultSectionTitle = $('.lp-kit-hp-sec-nav ul li:first-of-type a').text();

			//var numberOfMenuItems = $('.lp-kit-hp-sec-nav ul li').length;

			$('.lp-kit-hp-sec-nav ul li').each(function (index, element) {

				categoryMenuWidth += $(this).outerWidth(true);

				var section = $(this).find('a').attr('href').replace('#','');

				var offset = $('a[name="' + section + '"]').offset().top;


				if ($(this).next().length) {
					var nextSection = $(this).next().find('a').attr('href').replace('#','');
					var offsetNext = $('a[name="' + nextSection + '"]').offset().top;
				} else {
					var offsetNext = $('.lp-section-cta').offset().top;
				}

				var sectionHeight = offsetNext - offset;

				var myWindowScrollTop = windowScrollTop + menuContainerInnerHeight + navlineHeight;


				if (offset <= myWindowScrollTop && offset + sectionHeight > myWindowScrollTop) {
					$(this).find('a').addClass('lp-kit-hp-sec-nav-item-current');
					//$('.lp-kit-hp-sec-nav select option[value="#' + section + '"]').attr('selected', 'selected');
				} else {
					$(this).find('a').removeClass('lp-kit-hp-sec-nav-item-current');
					//$('.lp-kit-hp-sec-nav select option[value="#' + section + '"]').removeAttr('selected');
				}

				//if (index === (numberOfMenuItems - 1)) {
					//var hintText = $('.lp-kit-hp-sec-nav-item-current').text();
					//if (hintText == '') {
					//	hintText = $('.lp-kit-hp-sec-nav ul li:first-of-type a').text();
					//}
					//$('.lp-kit-hp-sec-nav-select-hint > span').text(hintText);
				//}


			});

			menuContainer.toggleClass( 'lp-kit-hp-sec-nav-overflowed', categoryMenuWidth > menuContainerInner.width());

		};

	var handleHashTimer;
	$('.lp-kit-hp-sec-nav').on('change', 'select', function (e) {
		window.location.hash = 'top';
		var sectionToGo = $(this).val();
		clearTimeout(handleHashTimer);
		handleHashTimer = setTimeout(function() {
			window.location.hash = sectionToGo;
			$('.lp-kit-hp-sec-nav select')[0].selectedIndex = -1;
			HandleEffectWhenJumpingToAnchor();
		}, 1);

	});



	$('.lp-kit-hp-sec-nav ul li a').on('click', function (e) {
		HandleEffectWhenJumpingToAnchor();
	});

	var clickTabItemEffectEnable;
	var clickTabItemEffectShow;
	var firstRun = true;

	function HandleEffectWhenJumpingToAnchor() {
		if (firstRun) {
			$('.lp-section').addClass('lp-section-visible');
			firstRun = false;
		}

		$('.portal').addClass('enable-main-overlay show-main-overlay');

		clearTimeout(clickTabItemEffectShow);
		clearTimeout(clickTabItemEffectEnable);

		clickTabItemEffectShow = setTimeout(function() {
			$('.portal').removeClass('show-main-overlay');
		}, 50);


		clickTabItemEffectEnable = setTimeout(function() {
			$('.portal').removeClass('enable-main-overlay');
		}, 700);
	}

})(jQuery);