/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 14:13
 */

function webnode2015_modalControl($, dependencies) {
	var classControl = dependencies.classControl;

	/**
	 * Funkce pro obsluhu modalovych oken a menu
	 *
	 * @param modalType - typ modalove okna, nebo požadované události
	 */
	function modalControl(modalType) {
		var b = $('body');
		var w = $('.wrapper');
		var m = $('.modal');
		var sidemenu = $('.side-menu');

		if (
			// modalType === "signup" ||
			// modalType === "signup2" ||
			// modalType === "login"
			modalType === "cookie"
		)
		{
			return;
		}


		// Zavření všech otevřených modalu, menu a filtru
		$('.open')
			.filter(function (index, element) {
				return (
					!$(element).hasClass('checkout-payment-method') &&
					!$(element).hasClass('domains-container') &&
					!$(element).hasClass('domains-cart')
				);
			})
			.removeClass('open');

		// Vrácení původní pozice v případě předešlého aktivního user menu
		b.css('top','');
		b.css('bottom', '');
		b.removeClass('active');
		b.removeClass('active-user-menu');
		b.removeClass('active-ios-overlay');

		// Vrácení původní pozice po úpravě pro ošetření modalu login a reg na touch zařízeních, zejména kvůli iOS
		m.css('top','');
		w.css('top','');
		w.css('bottom', '');

		if (b.attr('data-last-y-offset')) {
			window.scrollTo(0,parseInt(b.attr('data-last-y-offset')));
			b.removeAttr('data-last-y-offset');
		}

		if (w.attr('last-scroll-top')) {
			window.scrollTo(0,parseInt(w.attr('last-scroll-top')));
			w.removeAttr('last-scroll-top');
		}

		switch (modalType) {

			// Otevření modalu s live preview šablony
			case "openPreview":
				classControl('active-preview');
				$('.live-preview').addClass('open');
				break;

			// Odebrání přiznaků jaký typ modalu se zobrazuje
			default:
				classControl();
		}
	}

	// function priceListModalControl(modalType, packageIdentifier) {
	// 	// skryjeme vsechna tlacitka s pokracovanim v nakupu
	// 	$('.price-list-continue-buttons').hide();
	//
	// 	// a zobrazime jen tlacitko pro dany balicek
	// 	$('.price-list-continue-buttons')
	// 		.filter(function (index, element) {
	// 			return $(element).attr('data-package-identifier') === packageIdentifier;
	// 		})
	// 		.show();
	//
	// 	modalControl(modalType);
	// }

	// /**
	//  *
	//  */
	// function tableDataModalControl() {
	//
	// 	if ($('body').hasClass('active-table-data')) {
	// 		var
	// 			windowHeight = $(window).height(),
	// 			headerHeight = $('.modal-section:not(.hide) .table-data-modal-header').height(),
	// 			modalSpacingContentScroll = 40,
	// 			modalSpacingWrapperScroll = 20,
	// 			modalMultiplier = 0.8; // max-height is 80%
	//
	// 		if (windowHeight < 600) {
	// 			modalMultiplier = 0.9; // max-height is 90%
	// 		}
	//
	// 		toolbarHeight = $('.modal-section:not(.hide) .table-data-modal-toolbar').height();
	// 		if (!toolbarHeight) {
	// 			toolbarHeight = 0;
	// 		};
	//
	// 		var limitContentHeight = (windowHeight * modalMultiplier).toFixed() - ( 2 * modalSpacingContentScroll ) - headerHeight - toolbarHeight;
	//
	// 		if (limitContentHeight > 150) {
	// 			$('.modal-section.with-content:not(.hide) .table-data-modal-content').addClass('scroll-shadows').css('maxHeight',limitContentHeight).css('overflowX','hidden').css('overflowY','auto');
	// 			$('.table-data-modal').removeClass('modal-wrapper-allow-scroll').addClass('modal-content-allow-scroll');
	// 			$('.table-data-modal-wrapper').removeClass('scroll-shadows').css('height','');
	// 		} else {
	// 			$('.modal-section.with-content:not(.hide) .table-data-modal-content').removeClass('scroll-shadows').css('maxHeight','').css('overflowX','').css('overflowY','');
	// 			$('.table-data-modal').addClass('modal-wrapper-allow-scroll').addClass('modal-content-allow-scroll');
	// 			$('.table-data-modal-wrapper').css('height',windowHeight - ( 2 * modalSpacingWrapperScroll ) ).addClass('scroll-shadows');
	// 		}
	// 	}
	// }

	return {
		modalControl: modalControl,
		// priceListModalControl: priceListModalControl,
		// tableDataModalControl: tableDataModalControl
	};
}

window.webnode2015_modalControl = webnode2015_modalControl;
