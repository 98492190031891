/**
 * @fileoverview
 *
 * @author richard.bures@webnode.com (Richard Bureš)
 * @datetime 30. 8. 2019 13:13
 */

jQuery.noConflict();

// webnode2015_wndPlugin(jQuery, window, document);

jQuery(document).ready(function(){ P2015(jQuery); });

function P2015($) {

	/**
	 *
	 * @type {{deviceDetection: Function, portalHeader: Function}}
	 */
	var inicializers = webnode2015_inicializers($);

	/**
	 *
	 * @type {{
	 * classControl: *,
	 * }}
	 */
	var dependencies = webnode2015_dependency_fns($);

	/**
	 *
	 * @type {{modalControl: *}
	 */
	var modals = webnode2015_modalControl($, dependencies);
	// ovládání modalu dám do scope window
	window.modalControl = modals.modalControl;
	// window.priceListModalControl = modals.priceListModalControl;
	// window.tableDataModalControl = modals.tableDataModalControl;

	var clickEvents = window.webnode2015_clickEvents && webnode2015_clickEvents($, dependencies);


	// Ovladač FAQ, zatím použitý jen na stránce pricelistu (domenoveho ceniku a transferu domeny, normalni cenik uz je v reactu)
	$('.section-faq-item-outer h3').off('click').on('click', function(e) {

		var faqDetailParent = $(this).parent();
		var faqDetailContent = faqDetailParent.find('.section-faq-item-inner');

		$('.section-faq-item-inner').not(faqDetailContent).slideUp(250);

		if (faqDetailParent.hasClass('open')) {
			faqDetailParent.removeClass('open');
		} else {
			$('.section-faq-item-outer').removeClass('open');
			faqDetailParent.addClass('open');
		}

		faqDetailContent.stop(false, true).slideToggle(250);

		e.preventDefault();

	});

	window.webnode2015_handleTooltip && window.webnode2015_handleTooltip($);

	/**
	 * Inicializace funkcí
	 */
	$(document).ready(function () {

		inicializers.deviceDetection.call(window);

		// uložíme si funkci,
		// abychom ji mohli později volat po obnovení seznamu projektů
		if (clickEvents)
		{
			window.onProjectsRefresh = clickEvents.bind($);
			window.onProjectsRefresh();
		}

		inicializers.portalHeader.call(window);
		// tableDataModalControl();

		// $('body:not(touched) textarea.js-auto-expand').textareaAutoSize();

		$(window).on('resize orientationchange', function () {
			inicializers.portalHeader.call(window);
			// tableDataModalControl();

			// $('body:not(touched) textarea.js-auto-expand').trigger('input');
		});


		//přidává informační třídu, že je sekce již viditelná

		checkSectionVisibility();

		$(document).on('scroll resize orientationchange', function () {
			checkSectionVisibility();
		});

		function checkSectionVisibility()
		{
			var pageTop = $(document).scrollTop();
			var pageBottom = pageTop + $(window).height();
			var tags = $('.lp-section');

			for (var i = 0; i < tags.length; i++) {
				var tag = tags[i];

				if ($(tag).hasClass('lp-section-effect-from-top')) {
					if ($(tag).position().top + ($(tag).height()/4) < pageBottom) {
						$(tag).addClass('lp-section-visible');
					}
				} else {
					if ($(tag).position().top + ($(tag).height()/2) < pageBottom) {
						$(tag).addClass('lp-section-visible');
					}
				}


			}
		}
	});

};